import { Pipe, PipeTransform } from '@angular/core';

import { ExtractParams, ExtractPaths, ExtractRoute, NavService } from './nav.service';
import { APP_ROUTES } from './routes';

@Pipe({
  name: 'route',
  standalone: true,
})
export class RoutePipe implements PipeTransform {
  constructor(private navigator: NavService) {}
  transform(
    path: ExtractPaths<typeof APP_ROUTES>,
    params?: ExtractParams<ExtractRoute<ExtractPaths<typeof APP_ROUTES>, typeof APP_ROUTES>>,
  ): string {
    return this.navigator.routeOf(path, params) ?? '';
  }
}
