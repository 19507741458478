import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Inject,
  input,
  output,
  signal,
} from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateModule } from '@ngx-translate/core';

import { Analytics, ANALYTICS } from '@smw/front-analytics';
import { NavService, RoutePipe } from '@smw/front-navigation';
import { JourneyStore, Space, SpaceStore, UserStore } from '@smw/front-stores';
import { ButtonComponent, MenuDirective } from '@smw/front-ui';
import { IconComponent, MenuTriggerDirective } from '@smw/front-ui';
import { AuthenticationService } from '@so-many-ways/angular-authentication';

import { SidebarMenuComponent } from './menu/menu.component';

type NavItem = {
  icon: string;
  label: string;
  path: string;
  disabled?: boolean;
};

@Component({
  selector: 'smw-sidebar',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive,
    TranslateModule,
    MenuTriggerDirective,
    MenuDirective,
    IconComponent,
    ButtonComponent,
    SidebarMenuComponent,
    RoutePipe,
  ],
  templateUrl: 'sidebar.component.html',
  styleUrls: ['./sidebar.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  constructor(
    private router: Router,
    private nav: NavService,
    private userStore: UserStore,
    private journeyStore: JourneyStore,
    private authService: AuthenticationService,
    private spaceStore: SpaceStore,
    @Inject(ANALYTICS) private analytics: Analytics,
  ) {
    effect(() => {
      console.log({
        profile: this.journeyStore.isProfileCompleted(),
        motivations: this.journeyStore.isMotivationsCompleted(),
        checkup: this.journeyStore.isCheckupCompleted(),
        full: this.journeyStore.isFullyCompleted(),
      });
    });
  }

  smallDevice = input(true);
  mediumDevice = input(false);
  largeDevice = input(false);
  close = output();

  viewingAs = computed(() => this.spaceStore.mode());

  collaboratorNavItems = computed<NavItem[]>(() => {
    const items: NavItem[] = [
      {
        icon: 'dashboard',
        label: marker('sidebar_dashboard_item'),
        path: this.nav.routeOf('collaborator.dashboard'),
      },
      {
        icon: 'profile',
        label: marker('sidebar_profile_item'),
        path: this.nav.routeOf('collaborator.introspection.profile'),
      },
      {
        icon: 'motivations',
        label: marker('sidebar_motivations_item'),
        path: this.nav.routeOf('collaborator.motivations'),
        disabled: !this.journeyStore.isProfileCompleted(),
      },
      {
        icon: 'checkup',
        label: marker('sidebar_checkup_item'),
        path: this.nav.routeOf('collaborator.checkup'),
        disabled: !this.journeyStore.isMotivationsCompleted(),
      },
    ];

    if (this.userStore.companyEnablesModules().includes('interview')) {
      items.push({
        icon: 'conversations',
        label: marker('sidebar_conversations_item'),
        path: this.nav.routeOf('collaborator.conversations'),
        disabled: !this.journeyStore.isCheckupCompleted(),
      });
      items.push({
        icon: 'actions',
        label: marker('sidebar_actions_item'),
        path: this.nav.routeOf('collaborator.actions'),
        disabled: !this.journeyStore.isCheckupCompleted(),
      });
    }

    return items;
  });
  managerNavItems = computed<NavItem[]>(() => {
    const items: NavItem[] = [];

    if (this.userStore.companyEnablesModules().includes('ai')) {
      items.push({
        icon: 'copilot',
        label: marker('sidebar_copilot_item'),
        path: this.nav.routeOf('manager.copilot'),
        disabled: false,
      });
    }

    if (this.userStore.companyEnablesModules().includes('interview')) {
      items.push({
        icon: 'conversations',
        label: marker('sidebar_conversations_item'),
        path: this.nav.routeOf('manager.conversations'),
        disabled: false,
      });
    }

    if (this.userStore.companyEnablesModules().includes('formations')) {
      items.push({
        icon: 'formation',
        label: marker('sidebar_training_item'),
        path: this.nav.routeOf('manager.formations'),
        disabled: false,
      });
    }

    return items;
  });
  hrNavItems = computed<NavItem[]>(() => [
    {
      icon: 'dashboard',
      label: marker('sidebar_dashboard_item'),
      path: this.nav.routeOf('hr'),
      disabled: false,
    },
  ]);

  items = computed(() => {
    switch (this.viewingAs()) {
      case 'collaborator':
        return this.collaboratorNavItems();
      case 'manager':
        return this.managerNavItems();
      case 'hr':
        return this.hrNavItems();
      default:
        return [];
    }
  });

  fullname = computed(() => this.userStore.fullname());
  initials = computed(() => this.userStore.firstname()[0]?.toUpperCase() ?? '');
  roles = computed(() => this.userStore.roles());
  company = computed(() => this.userStore.company()?.name ?? '');

  isMenuOpened = signal(false);

  changeSpace(mode: Space): void {
    this.spaceStore.mode.set(mode);
  }

  logout(): void {
    this.authService.logout().subscribe(() => {
      this.analytics.disconnectIdentity();
      this.router.navigateByUrl('/authentification');
    });
  }
}
